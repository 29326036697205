.main-footer {
  background-color: #dde3e7;
  display: flex;
  padding-top: 20px;
  a {
    color: #333333;
    text-decoration: none;
    font-size: 16px;
  }

  .social-media-links {
    a {
      margin-left: 20px;
      color: #212529;
    }
  }
  .copyright {
    margin-top: 60px;
    margin-bottom: 86px;
    color: #212529;
  }
}
