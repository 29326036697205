body {
  background: #f1f7fd;
  font-family: TheSans, sans-serif;
}

#root {
  height: 100vh;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
}

.list-profile-links {
  list-style: none;
  padding: 4px;
  li {
    list-style: none;
    .del-link {
      margin-right: -34px;
    }
  }
}

.form-group {
  margin-bottom: 25px;
}

.center-container {
  max-width: 654px !important;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }
}
.more-input {
  .cv-file-upload-container hr,
  .cv-file-upload-container .cv-area-txt-onlycv,
  .cv-file-upload-container .sw-cv-upload-card,
  .recordingAlignment,
  .questions-list,
  .selectReactionBox {
    max-width: 654px;
  }
}

@media only screen and (max-width: 600px) {
  .line-rectangle1 {
    margin-top: 0 !important;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.full-screen-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1031;
}

.modal-header .btn-close {
  font-size: 12px;
}
