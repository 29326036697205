.sc-cd-audio-form-group {
  padding: 16px 8px !important;
  display: flex;
  align-items: center;
  max-width: initial !important;
  max-height: initial !important;
  color: #bbbbbb;

  &.is-error {
    border-color: #dd1122;
  }

  &.being-record {
    button {
      &.rec-start-rec {
        background-color: #dd1122;
      }
    }
  }
  &.recorded {
    border: 2px solid #1781e3;
  }
  .center-column {
    flex: 1;
    display: flex;
    align-items: center;
    .voice-message-txt {
      margin-left: 12px;
    }
  }

  button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    box-shadow: none;
    width: 40px;
    color: #ffffff;
    line-height: 100%;
    height: 41px;
    &:hover {
      color: #ffffff;
    }

    &.rec-start-rec {
      background-color: #1781e3;
    }
  }
  .input-group-prepend {
    display: flex;
    align-items: center;
  }
}
