* {
  box-sizing: border-box;
}

html,
body,
[data-gjs-type='wrapper'] {
  min-height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: #f1f7fd !important;
  top: 0 !important;
}

[data-gjs-type='wrapper'] {
  overflow: auto;
  overflow-x: hidden;
}

* ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

* ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

* ::-webkit-scrollbar {
  width: 10px;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: auto;
}

.bs-stepper .step-trigger:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
}

.mic-ios {
  -webkit-appearance: none !important;
}

.container-modal {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  visibility: hidden;
  display: none;
  background-color: rgba(22, 22, 22, 0.5);
  z-index: 9999999999;
}

.modal {
  position: fixed;
  margin: 0 auto;
  top: 25%;
}

.cloud-dev-engineer {
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: center;
  font-weight: 800;
  margin-top: 8px;
}

.erzahl-uns-von-dir {
  color: #001155;
  font-family: TheSans, sans-serif;
  font-weight: 800;
  font-size: 64px;
  letter-spacing: -1px;
  line-height: 72px;
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}

.einfach-bewerben {
  max-height: 120px;
  max-width: 879px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 64px;
  letter-spacing: -1px;
  line-height: 72px;
  text-align: center;
  font-weight: 800;
  margin: 0 auto;
}

.logo {
  width: 137px;
  height: 40px;
  margin: 20px 0 20px 40px;
}

.header {
  border: 0 solid;
  height: 80px;
  background-color: white;
}

.icons-bold-bug-copy-icons-bold {
  height: 70px;
  width: 70px;
  margin-top: 48px;
  font-weight: bold;
  color: #001155;
}

.icons-bold-bag-plus-copy-icons-bold {
  height: 29.33px;
  width: 34.67px;
  margin-top: 48px;
  font-weight: bold;
  color: #001155;
}

.card-title {
  height: 40px;
  width: 286px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
}

.card-text {
  height: 64px;
  width: 380px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
}

.card-items {
  margin-top: 100px;
}

.card-body {
  background-color: white;
  margin: 10px;
}

.card-deck .card {
  border: none;
  box-shadow: 0 2px 24px 0 rgb(17 170 255 / 10%);
  flex: 1 0 0;
  margin-right: 15px;
  margin-left: 15px;
}

.card-deck {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  flex-flow: row wrap;
  display: flex;
}

.card-custom {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 5px;
}

.button-primary-primary {
  height: 48px;
  width: 327px;
  color: #ffffff;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  background-color: #1781e3;
  border: none;
  margin: 0 auto;
  cursor: pointer;
}

.form-moreinfo-txt,
.form-moreinfo-coffee-txt {
  width: 400px;
}

.form-back-txt {
  width: 260px;
}

#remainingC {
  float: left;
}

.weiter-home {
  margin-top: 56px;
  margin-bottom: 48px;
}

#former-employee-div {
  display: none;
}

.vielleicht-bevorzugs {
  max-height: 156px;
  max-width: 1428px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.25px;
  line-height: 28px;
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
}

.form-control {
  padding: 8px 16px;
}

.vielleicht-bevorzugs-home {
  max-height: 56px;
  max-width: 428px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.25px;
  line-height: 28px;
  text-align: center;
  font-weight: 800;
  margin-top: 116px;
}

.coding-switch {
  height: 48px;
  width: 327px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 19px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
  border: none;
  background-color: #ffffff;
  margin-top: 16px;
  border-radius: 5px;
}

.button-align {
  top: -4px;
  position: relative;
}

.f-s-20 {
  font-size: 20px;
}

.dashed {
  width: 400px;
  height: 50px;
  border-color: #cecccc;
  text-align: center;
  display: inline-block;
}

.sc-cv-form-button-ct {
  height: 48px;
  margin: 24px auto 0;
}

.file-upload-button-ct {
  width: 100% !important;
  max-width: 624px;
}

.voicebox {
  margin-left: 35%;
  position: relative;
  display: inline-block;
}

.voicebox img {
  float: right;
  width: 25px;
  height: 25px;
  border: none;
  position: absolute;
  right: 7px;
  top: 12px;
}

.maincontent {
  background-color: #f1f7fd;
  height: 100%;
}

.topContent {
  text-align: center;
  padding: 50px 0 50px 0;
}

.heading2 {
  height: auto;
  width: auto;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 50px;
  text-align: center;
}

.vielen-dank-fur-dein {
  margin-left: auto;
  margin-right: auto;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  max-width: 428px;
  height: auto;
  margin-top: 24px;
}

.wir-melden-uns-bei-d {
  height: auto;
  max-width: 428px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 24px auto 40px;
}

.button-copy-5-1 {
  height: 48px;
  width: 334px;
}

.button-copy-5 {
  height: 48px;
  width: 234px;
}

.footer {
  background-color: #dde3e7;
  width: 100%;
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  padding-bottom: 6%;
}

.footer_1 {
  background-color: #dde3e7;
  display: inline-block;
  width: 100%;
  // position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.left-footer {
  float: left;
  padding-left: 50px;
}

.right-footer {
  float: right;
  padding-right: 50px;
  font-family: TheSans, sans-serif;
}

.panel-heading {
  display: inline-block;
  max-width: 580px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.row-new {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 54px;
  margin-left: 54px;
  margin-top: 49px;
}

.bewirb-dich-jetzl-al {
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 56px auto 0;
}

.lblname {
  margin: 8px 0;
  height: 18px;
  color: #666666;
  font-family: TheSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
}

.linkfield {
  box-sizing: border-box;
  min-height: 48px;
  height: auto;
  width: 200px;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 5px;
  background-color: #ffffff;
}

.button-submit-cc-ct {
  max-width: 316px !important;
  width: 100%;
  justify-content: center;
}

.mobile-button {
  display: none;
}

.desktop-button {
  display: block;
}

#list-profile-links {
  list-style-type: none;
  text-align: left;
  padding-left: 0;
}

.ul-link-items {
  text-align: left;
  border-bottom: 1.5px solid #d1e6f9;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #1781e3;
}

.alignRight {
  padding-left: 30%;
}

.txtfield {
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ffffff;
}

.txtfield:hover {
  border: 1px solid #adadad;
}

.form-control:focus {
  border: none;
  box-shadow: 0 0 0 0.04rem #4d90fe;
}

.form-control::placeholder {
  color: #bbbbbb;
}

.pt-7rem {
  padding-top: 7rem !important;
}

.dark-shadow:focus {
  border: none;
  box-shadow: 0 0 0 0.04rem #4d90fe;
}

#social-contactNumber {
  color: inherit;
}

//     input:not(:placeholder-shown) {
//         border: 1px solid rgb(3 10 19 / 86%);
//     }

.add-link-field {
  padding-left: 20%;
  padding-right: 20%;
}

.vorname {
  padding-left: 50%;
  padding-right: 10%;
}

.lastname {
  padding-left: 5%;
  padding-right: 50%;
}

.white-bg {
  background-color: #ffffff;
}

.file-upload-button {
  position: relative;
  box-sizing: border-box;
  text-align: center;
}

input[type='file']::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.file-upload-button > input {
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.file-upload-button:focus {
  outline: none;
}

.box-erika {
  font-family: TheSans, sans-serif;
  text-align: left;
  max-width: 685px;
  line-height: 2;
  font-size: 14px;
}

.emailAlignment {
  font-family: TheSans, sans-serif;
  text-align: left;
  min-height: 50px;
  max-width: 660px;
}

.lblemailAlignment {
  font-family: TheSans, sans-serif;
}

.radio-button-label {
  height: 32px;
  width: 576px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
}

.radio-button-label-DifferentProfile {
  height: 32px;
  width: 576px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
}

.du-hast-technische-p {
  max-height: 80px;
  max-width: 428px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: center;
  font-weight: 800;
}

.wir-haben-haufige-pr {
  max-height: 64px;
  max-width: 308px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.erzahl-uns-ein-wenig {
  text-align: center;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 8px;
  max-width: 655px;
}

.erforderliche-date {
  text-align: center;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 16px 0 25px;
  font-weight: bold;
}

.backButton {
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.inner-maincontent {
  padding-top: 40px;
  margin-top: 3%;
}

.lblcheckbox {
  padding-left: 30%;
}

.svg-align {
  padding-left: 50%;
}

.sc-form-document-container {
  max-height: 252px;
  max-width: 624px;
  border: 1px dashed #d6d6d6;
  border-radius: 5px;
  background-color: #f1f7fd;
  box-sizing: border-box;
  margin-top: 15px;
}

.dokumente-hierher-zi {
  height: 24px;
  width: 327px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 20px auto 0;
  font-weight: 500;
}

.oder {
  height: 18px;
  width: 327px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin: 24px auto 0;
}

.sc-cv-form-button {
  height: 48px;
  width: 328px;
  margin: 24px auto 0;
}

.bg-wht {
  background-color: #ffffff;
}

.bg-skyBlue {
  background-color: #f1f7fd;
}

.bg-btn {
  background-color: #f1f7fd;
  box-sizing: border-box;
  height: 48px;
  width: 328px;
  border: 1px solid #0876db;
  border-radius: 5px;
}

.bg-btn-blue {
  //background-color: #1781E3;
  background-color: transparent;
  box-sizing: border-box;
  height: 48px;
  width: 328px;
  border: 1px solid #0876db;
  border-radius: 5px;
}

.bg-btn-white {
  background-color: #fff;
  box-sizing: border-box;
  height: 48px;
  width: 328px;
  border: 1px solid #0876db;
  border-radius: 5px;
}

.icons-ambulance {
  max-height: 29.33px;
  max-width: 34.67px;
  color: #001155;
}

.primary-lorem-weiter-dokumente {
  //background-color: #F1F7FD;
  height: 24px;
  width: 180px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: left;
}

.primary-lorem {
  background-color: #f1f7fd;
  height: 24px;
  width: 167px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

.primary-lorem-blue {
  //background-color: #1781E3;
  background-color: transparent;
  color: #1781e3;
  height: 24px;
  width: 167px;
  //color: #FFF;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

.primary-lorem-white {
  background-color: #fff;
  height: 24px;
  width: 167px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

.bi-paperclip {
  height: 20px;
  width: 20px;
  margin-top: 0;
  margin-left: 0;
}

.https-www-linkedin {
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
}

.format-pdf-jpg-ode {
  height: 18px;
  width: 328px;
  color: #666666;
  font-family: TheSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin: 16px auto 0;
  padding-bottom: 40px;
}

.weitere-angaben-opt {
  max-height: 80px;
  max-width: 654px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: left;
}

.weitere-angaben-opt-ct {
  max-width: 624px;
}

.hier-kannst-du-dokum {
  min-height: 32px;
  height: auto;
  max-width: 624px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: left;
  margin-top: 10px;
}

.linkedin-link-button {
  box-sizing: border-box;
  height: 48px;
  width: 654px;
  border: 1px solid #0876db;
  border-radius: 5px;
}

.addSpacingLinkBtn {
  margin-bottom: 80px;
}

.linkedin-link-button1 {
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  border: 1px solid #0876db;
  border-radius: 5px;
  background-color: #ffffff;
  vertical-align: middle;
  margin-top: 0;
  text-align: center;
}

.link-hinzufugen1 {
  max-height: 24px;
  max-width: 124px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
  background-color: unset;
}

/*----------------------*/

.questions-cont {
  margin-top: 80px;
  @media only screen and (max-width: 600px) {
    margin-top: 0px;
  }
}

#question-containerid {
  display: none;
}

.questions-header {
  /*height:254px;*/
  text-align: center;
  max-width: 624px;
}

.fachfragen {
  height: 72px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 64px;
  letter-spacing: -1px;
  line-height: 72px;
  text-align: center;
  margin-top: 16px;
  font-weight: 800;
}

.um-dich-besser-kenne {
  height: 64px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  text-align: center;
  margin-top: 32px;
}

.question-title {
  height: 18px;
  color: #666666;
  font-family: TheSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 24px;
}

.questions-list {
  margin-top: 40px;
  max-width: 624px;
  text-align: left;
}

.question-answer {
  margin-top: 12px;
}

/*----------------------*/

/*--------Inernal jon link -----------*/

.internal-job-label {
  margin-top: 30px;
}

/*----------------------*/

.combined-shape {
  height: 22px;
  width: 22px;
}

.selectOptionReact {
  max-height: 24px;
  max-width: 337.87px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.wie-bist-du-auf-dies {
  max-width: 554px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 32px;
}

.selectReactionBox {
  max-height: 100%;
  max-width: 624px;
  text-align: left;
}

.dropdowns-default-320-ddleView {
  box-sizing: border-box;

  max-width: 654px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 20px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 4%;
}

.dropdowns-default-320 {
  box-sizing: border-box;
  max-height: 48px;
  max-width: 619px;
  border: 1px solid #adadad;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 20px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.mochtest-du-dein-lin {
  max-height: 164px;
  max-width: 654px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 32px auto 0;
}

.rectangle {
  height: 48px;
  width: 327px;
  border-radius: 4px;
  background-color: #1781e3;
  margin-top: 16px;
}

.du-ladst-dein-cv-hoc {
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: auto;
  //width: 390px;
  padding-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
}

.du-ladst-dein-cv-hoc-3 {
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: 64px;
  width: 390px;
  padding-left: 24px;
  padding-right: 118px;
  margin-top: 26px;
}

.bi-lightning {
  color: #001155;
  margin-top: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

.i-drafts {
  color: #001155;
  margin-top: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

.bi-cup {
  color: #1781e3;
  margin-right: 15px;
}

.cursor {
  height: 50px;
  width: 34px;
  margin-left: 30px;
  margin-top: 31px;
  color: #001155;
}

.cursor-page2 {
  height: 50px;
  /*width: 34px;*/
  margin-top: 24px;
  color: #001155;
}

.xing-line {
  border-left: 1px solid white;
  height: 54px !important;
  position: absolute;
  margin-top: -15px !important;
  margin-left: 32px !important;
}

.xing-button-page2 {
  margin-left: 28px;
}

.mit-linked-in-verknup {
  height: 24px;
  width: 188px;
  color: #ffffff;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

svg {
  vertical-align: baseline !important;
}

.linkdin-logo {
  top: 0.1em;
  position: relative;
}

.rectangle-3 {
  height: 48px;
  width: 327px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 40px;
}

.rectangle-4 {
  height: 48px;
  width: 200px;
  border-radius: 4px;
  background-color: #ffffff;
}

.bewerben-mit-einem-a {
  height: 24px;
  width: 273px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

.zuruck-copy {
  height: 24px;
  width: 60px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.profil-link-oder-pdf {
  max-height: 172px;
  max-width: 879px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 64px;
  letter-spacing: -1px;
  line-height: 72px;
  text-align: center;
  margin: 0 auto;
  font-weight: 800;
  margin-top: 16px !important;
  //font-weight: bolder;
}

.align-center {
  display: block;
  text-align: -webkit-center;
  margin: 0 auto;
}

.buttonstyle {
  border-color: #1781e3;
  border-width: 0;
}

.buttonstyle-xing {
  border-color: rgb(0 95 113);
  border-width: 0;
}

.audio-text {
  max-height: 336px;
  max-width: 654px;
}

.mochtest-du-uns-noch {
  /* margin-top: 76px !important;*/
  max-height: 80px;
  max-width: 654px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: left;
}

.sc-cd-audio-form-group-active {
  border: 2px solid #1781e3;
  border-radius: 5px;
  background-color: #ffffff;
  max-height: 84px;
  max-width: 624px;
}

.sc-cd-audio-form-group {
  border: 1px dashed #d6d6d6;
  border-radius: 5px;
  background-color: #ffffff;
  max-height: 84px;
  max-width: 624px;
}

.sc-cd-audio-stop-form-group {
  border: 2px dashed #dd1122;
  border-radius: 5px;
  background-color: #ffffff;
  max-height: 84px;
  max-width: 624px;
}

.sc-cd-audio-play-form-group {
  border: 2px solid #1781e3;
  border-radius: 5px;
  background-color: #ffffff;
  max-height: 80px;
  max-width: 654px;
}

.hyperLink {
  color: #1781e3;
  font-weight: 500;
}

.box-content {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 22.5px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
}

.sc-cd-audio-form-group input {
  border: none;
}

.sc-cd-audio-form-group div {
  border: none;
}

.sc-cd-audio-form-group div span {
  border: none;
}

.sc-cv-audio-input-text {
  position: absolute;
  top: 18%;
  max-height: 48px;
  max-width: 48px;
  background: #1781e3;
  color: #ffffff;
  right: 0;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.sc-cv-audio-delete-input-text {
  position: absolute;
  top: 18%;
  max-height: 48px;
  max-width: 48px;

  color: #ffffff;
  right: 0;
  font-size: 13px;
  border-radius: 5px;
}

.sc-cv-audio-stop-input-text {
  position: absolute;
  top: 18%;
  max-height: 48px;
  max-width: 48px;
  background: #dd1122;
  color: #ffffff;
  right: 0;
  font-size: 13px;
  border-radius: 5px;
}

.sc-cv-audio-input {
  width: 100%;
  position: relative;
  padding: 10px 35px 40px 5px;
  color: #bbbbbb;
  resize: none;
  height: 80px;
  text-align: left;
  vertical-align: middle;
}

.textarea.form-control {
  height: 68px !important;
}

.CVform {
  max-width: 655px;
  margin: auto;
  padding-bottom: 116px;
}

.audio {
  height: 80px;
  max-width: 624px;
  padding: 30px 20px;
  margin-bottom: 10px;
  color: #bbbbbb;
  font-family: TheSans, sans-serif;
}

.recordingAlignment {
  max-width: 624px;
  text-align: left;
}

.recordingAlignment .order-box {
  text-align: center;
}

.audioText {
  padding: 30px 20px;
}

.no-pointer {
  cursor: none;
}

.adjustProgressBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
}

.CoffeeTalkInputField {
  box-sizing: border-box;
  max-height: 240px;
  max-width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  background-color: #ffffff;
}

.selectReactionBoxDdlView {
  max-height: 100%;
  /*max-width: 621px;*/
  text-align: left;
}

.styleDanger {
  margin-left: 5.5%;
}

div#errorReferalEmployee {
  text-align: left;
  display: none;
}

.sc-cv-form-select-gender {
  width: 100% !important;
}

.alignGenderPDFUpload {
  max-width: 654px;
}

.stylingwidth {
  padding-top: 116px;
}

.jobPostingRecommendationSection {
  margin-top: 80px;
}

.linkColor {
  color: #1781e3;
}

.align-top {
  font-family: TheSans, sans-serif;
}

.order-align {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: TheSans, sans-serif;
  font-size: 20px;
}

.removeMargin {
  margin-top: 0 !important;
}

.fileAddArea {
  padding-bottom: 40px;
}

.elapsed-time-text,
.selectReactionBoxDdlView {
  visibility: hidden;
}

.policyFontStyle {
  font-size: 20px !important;
}

.mit-linked-in-verknup1 {
  /*height: 80px;*/
  /*max-width: 267px;*/
  /*min-width:214px;*/
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: center;
  margin-top: 17px;
  margin-right: 24px;
  margin-left: 24px;
  font-weight: 700;
}

.ihr-vollstandiges-li {
  height: 48px;
  /*min-width: 214px;*/
  font-family: TheSans, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 22px;
}

.linkedin-description-page2 {
  position: absolute;
  background-color: white;
  height: 72px;
  width: 214px;
  margin-top: 14px;
}

#frameGoesHere {
  margin-bottom: 14px;
}

.rectangle-home-1-page2 {
  text-align: left;
  /*height:320px;*/
  min-height: 320px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 24px 0 rgba(17, 170, 255, 0.1);
  margin-bottom: 20px;
}

.linkedin-box {
  height: 348px;
}

.rectangle-page2-xing {
  min-height: 40px;
  min-width: 177px;
  border-radius: 4px;
  margin: 24px 10px;
  background: #005f71 url(../images/xing-white.svg) no-repeat top left;
  background-size: 25px 23px;
  background-position-y: 9px;
  background-position-x: 9px;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.rectangle-page2-xing-org {
  height: 55px;
  width: 230px;
  border-radius: 4px;
  background-color: rgb(0 95 113);
  margin: 24px 10px;
  background-size: 35px 33px;
  background-position-y: 11px;
  background-position-x: 8px;
}

.xing-logo {
  height: 54px !important;
}

.xing-buuton {
  height: 40px;
  width: 44px;
  border-right: 1px solid;
  border-color: white;
}

.rectangle-page2 {
  min-height: 40px;
  min-width: 177px;
  background-color: #1781e3;
  border-radius: 4px;
  margin: 24px 10px;
}

.rectangle-page2-org {
  height: 55px;
  width: 230px;
  border-radius: 4px;
  background-color: #1781e3;
  margin: 24px 10px;
}

.rectangle-3-page2 {
  min-height: 41px;
  min-width: 177px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #0876db;
  margin: 24px 10px;
}

.rectangle-3-page2-org {
  height: 55px;
  width: 230px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #0876db;
  margin: 24px 10px;
}

.rectangle-home-1 {
  text-align: left;
  height: 220px;
  min-height: 220px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 24px 0 rgba(17, 170, 255, 0.1);
  margin-bottom: 20px;
}

.form-label-gender {
  width: 100%;
}

.additonalLinkSection {
  margin-top: 40px;
}

.FAQSection {
  margin-bottom: 140px;
  margin-top: 116px;
}

.weitere-dokumente-hi {
  height: 30px;
  width: 556px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
}

.weitere-dokumente-hi-desktop {
  height: 30px;
  width: 204px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  cursor: pointer;
}

.flash-icon-style {
  margin-top: 8px;
}

.mobile-container-cv {
  display: none;
}

.line-rectangle1 {
  height: 0;
  width: 625px;
  opacity: 0.2;
  margin-top: 28px;
  margin-bottom: 20px;
  background-color: #5f7577;
}

.field-1-weiter-dokumente {
  box-sizing: border-box;
  height: 151px;
  width: 624px;
  border: 1px dashed #d6d6d6;
  border-radius: 5px;
  background-color: #f1f7fd;
}

.field-1 {
  box-sizing: border-box;
  height: 151px;
  width: 654px;
  border: 1px dashed #d6d6d6;
  border-radius: 5px;
  background-color: #f1f7fd;
}

#upload_link {
  text-decoration: none;
}

#upload {
  display: none;
}

.blue-upload-link-desktop {
  width: 100%;
  height: auto;
  margin: 0 auto;
  /* padding: 10px; */
  position: relative;
}

.weiter-dokumente {
  height: 24px;
  //width: 327px;
  width: 100%;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  /*margin: 40px 49px 21px 32px;*/
  margin-top: 40px;
  margin-bottom: 21px;
}

.oder-weiter-dokumente {
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-left: 8px;
  margin-right: 8px;
}

.format-pdf-jpg-ode-weiter-dokumente {
  height: 18px;
  width: 328px;
  color: #666666;
  font-family: TheSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin: 21px auto 0;
  padding-bottom: 40px;
}

.btn-weiter-dokumente {
  box-sizing: border-box;
  height: 48px;
  width: 328px;
  border: none;
  background-color: #f1f7fd;
}

@media only screen and (max-width: 600px) {
  .center-container {
    .other-docs {
      margin-bottom: 0px !important;
    }
  }
  .FAQSection {
    margin-bottom: 93px !important;
  }
  .rectangle,
  .rectangle-3 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .bs-stepper-header {
    margin: 0 !important;
    text-align: center;
  }

  .bewirb-dich-jetzl-al {
    margin-top: 40px;
  }
  .order-link-section {
    padding: 0 24px 0 24px;
  }
  .flash-icon-style {
    margin-top: 10px !important;
  }
  .bewirb-dich-jetzl-al,
  .vielen-dank-fur-dein,
  .wir-melden-uns-bei-d,
  .wir-haben-haufige-pr,
  .box-content,
  .vielleicht-bevorzugs-home,
  .mochtest-du-dein-lin {
    font-size: 16px;
  }
  .cloud-dev-engineer,
  .du-hast-technische-p,
  .additional-info-txt {
    font-size: 20px;
  }
  .und-so-gehts-weiter {
    font-size: 28px !important;
  }
  .wir-melden-uns-bei-d {
    padding-left: 10px;
    padding-right: 10px;
  }
  .einfach-bewerben {
    font-size: 40px;
  }
  .audioSectionDifferentProfile {
    height: auto;
    max-height: initial;
  }
  .cursor,
  .schritt-1 {
    margin-top: 26px !important;
  }

  .back-button-container {
    margin-bottom: 99px !important;
  }
  .dp-page-cv-container .weitere-angaben-opt.adapt-font,
  .dp-page-cv-container .hier-kannst-du-dokum {
    margin-left: 0;
    margin-right: 0;
  }
  .rectangle-home-1 {
    text-align: left;
    height: 220px;
    min-height: 220px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 24px 0 rgb(17 170 255 / 10%);
    margin-bottom: 20px;
  }
  .und-so-gehts-weiter-copy {
    font-weight: 800;
    height: 32px;
    color: #001155;
    font-family: TheSans, sans-serif;
    font-size: 28px;
    letter-spacing: -0.5px;
    line-height: 32px;
    text-align: center;
  }
  .CVform {
    padding-bottom: 96px;
  }

  .sc-form-document-container {
    margin-top: 24px;
  }

  .desktop-button-cc {
    display: none;
  }

  .mobile-button-cc {
    display: block;
  }

  .file-upload-button-cc {
    width: 98% !important;
  }

  .erzahl-uns-von-dir {
    font-size: 40px;
    padding: 20px;
  }

  .erforderliche-date {
    font-size: 16px;
  }

  .cloud-dev-engineer {
    font-size: 20px;
  }

  .checkBoxAlert {
    border-radius: 0 !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-size: 18px !important;
    padding: 21px 38px !important;
    color: white !important;
    background-color: #cf4a0c !important;
    border-color: #cf4a0c !important;
    max-width: 50%;
    margin: 1% auto auto 25%;
  }
  .input-group-prepend.displayRecordStop,
  .input-group-prepend.displayPlayRecord {
    margin-top: 7%;
  }
  .form-control.sc-cv-audio-input {
    padding-top: 7%;
  }
  .alignWidth {
    background: white;
    padding: 2%;
  }

  .format-pdf-jpg-ode-ct {
    max-width: 100% !important;
  }

  .schritt-row {
    width: 100% !important;
  }

  .left-footer,
  .right-footer {
    width: 100%;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
  }

  .panel-heading {
    display: inline-block;
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .left-content,
  .footer-link {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    text-align: center;
  }
  .row {
    display: inline-block;
    width: 100%;
    padding-top: 5px;
  }
  .links-footer-right {
    padding-left: 35px;
  }

  .content-copy-right {
    padding-left: 20px;
    font-family: TheSans, sans-serif;
  }

  .modal-header {
    padding: 24px 16px 24px 14px !important;
  }
  .hast-du-ein-online-p {
    width: 100% !important;
    font-size: 20px;
  }
  .modal-body .col-sm-12 {
    margin-top: 25%;
  }

  sdx-button {
    padding: 0 !important;
    font-size: 14px !important;
  }

  .button-reset {
    font-size: 14px !important;
  }
  .adapt-height-input-field {
    max-height: 40px;
  }
  .adaptDimensions {
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }
  .adaptDimensions .adaptDimensions {
    padding: 0;
    width: 100%;
  }
  .stylingwidth {
    padding-top: 116px !important;
  }

  // .adapt-font{
  //     font-size: 20px;
  // }

  .adapt-font-coffeTalk {
    font-size: 16px;
  }

  .bg-btn {
    height: 48px;
    width: 200px;
    border: 1px solid #0876db;
    border-radius: 5px;
    margin: 0 !important;
  }

  .distanceLeft {
    width: 100%;
  }

  .xingOderLinkedin {
    margin: 0 !important;
    width: 100%;
  }
  .logo {
    margin: 20px 0 20px 11px;
  }
  .deine-bewerbung-wurd {
    font-size: 25px !important;
    line-height: 100% !important;
  }
  .profil-link-oder-pdf {
    font-size: 36px;
    margin: 8px 24px 24px 25px !important;
  }
  .du-hast-technische-p,
  .wir-haben-haufige-pr {
    //font-size: 18px;
    text-align: center;
  }
  .adapt-label-font {
    text-align: center !important;
    font-size: 20px;
  }

  .wie-bist-du-auf-dies {
    // text-align: center !important;
    height: 48px;
    width: 360px;
    color: #001155;
    font-family: TheSans, sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .mochtest-du-uns-noch {
    height: 48px;
    width: 360px;
    color: #001155;
    font-family: TheSans, sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 24px;
    margin-left: 0 !important;
  }

  .voice-adjust {
    margin-left: 10px !important;
  }

  .more-details-headline-txt {
    font-size: 20px;
    font-family: TheSans, sans-serif;
  }

  .weitere-angaben-opt,
  .hier-kannst-du-dokum {
    text-align: left !important;
    margin-left: 0 !important;
    margin-right: 25px;
  }
  .float-md-right {
    float: right !important;
  }
  .link-hinzufugen {
    width: 360px !important;
  }
  .jobPostingRecommendationSection {
    padding: 0 15px;
  }
  .profile-list-cv-container {
    padding-top: 0 !important;
  }
  .profile-list-cv {
    padding-top: 0 !important;
  }
  .hyperLink-cc,
  .data-privacy-long2-txt-cc {
    font-size: 16px;
  }
  .data-privacy-long2-txt-cc {
    font-size: 20px;
  }

  // .hier-kannst-du-dokum {
  //     font-size: 14px;
  // }
  .cv-lebenslauf {
    margin-top: 6%;
  }
  .row-new {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rectangle {
    width: calc(100% - 20px);
    border-radius: 4px;
    margin-right: 10px;
  }
  .rectangle-3 {
    width: calc(100% - 20px);
    border-radius: 4px;
    margin-right: 10px;
  }
  .vielleicht-bevorzugs-home {
    max-width: 290px;
  }
  .steps-slider .rectangle-home-2 {
    max-width: 240px;
    height: 250px;
    margin-left: 35px;
    margin-top: 10px;
  }
  .steps-slider .rectangle-home-3 {
    max-width: 240px;
    height: 250px;
    margin-left: 35px;
    margin-top: 10px;
  }
  .du-ladst-dein-cv-hoc {
    //max-width:250px;
  }
  .du-ladst-dein-cv-hoc-3 {
    padding-right: 0;
    width: 240px;
  }
  .coding-switch {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
  }
  .coding-switch-kaffi {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
  }
  .button-primary-primary {
    width: calc(100% - 20px);
    margin: 0 10px;
    font-size: 16px;
  }
  .distanceBottom {
    padding-bottom: 19%;
  }
  .footer {
    padding-bottom: 0 !important;
  }
  .line-3-copy-5 {
    width: 88%;
    margin-right: 0;
  }
  .https-github-com {
    width: 0 !important;
  }
  .link-1 {
    height: 30px;
    width: 302px !important;
  }
  .cv-alex-muller-pdf {
    font-size: 13px !important;
  }
  .inner-maincontent {
    padding-top: 0;
    margin: 0;
  }
  .inner-content-cc {
    padding-top: 40px;
  }
  .selectReactionBoxDdlView {
    //margin-left: 5.5%;
  }
  .elapsed-time-text {
    width: fit-content;
  }

  .padding-l-xs-14 {
    padding-left: 14px;
  }

  .mit-linked-in-verknup1 {
    height: auto;
    color: #001155;
    font-family: TheSans, sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 24px;
    text-align: center;
    /*width: 262px !important;*/
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .ihr-vollstandiges-li {
    height: auto;
    font-family: TheSans, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    /*width: 262px !important;*/
    margin-top: 8px;
    margin-right: 24px;
    margin-left: 24px;
  }
  .card-deck {
    width: 100%;
    flex-flow: column wrap;
  }
  .linkedin-description-page2 {
    position: absolute;
    background-color: white;
    height: 77px !important;
    width: 262px;
  }
  #frameGoesHere {
    margin-top: 50px;
  }

  .rectangle-home-1-page2 {
    min-height: 260px;
  }
  .linkedin-box {
    height: 282px;
  }

  /*-----------------------*/
  .fachfragen {
    height: 42px;
    color: #001155;
    font-family: TheSans, sans-serif;
    font-size: 34px;
    letter-spacing: -1px;
    line-height: 42px;
    text-align: center;
    margin-top: 8px;
    font-weight: 800;
  }
  .um-dich-besser-kenne {
    height: 72px;
    max-width: 328px;
    color: #333333;
    font-family: TheSans, sans-serif;
    font-size: 18px;
    letter-spacing: -0.1px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    padding-top: 16px;
  }

  .question-title {
    height: 36px;
    max-width: 326px;
    color: #666666;
    font-family: TheSans, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 20px;
  }
  /*---------------------*/
}

@media only screen and (max-width: 904px) {
  .dropdowns-default-320 {
    width: 96%;
  }

  .adaptDimensions-ct {
    margin: 0 24px !important;
  }

  .schritt-1 {
    font-size: 20px !important;
  }

  .cursor {
    width: 34px;
    height: 46px;
  }

  .mobile-button {
    display: block;
  }
  .desktop-button {
    display: none;
  }
  .du-ladst-dein-cv-hoc {
    font-size: 16px !important;
  }

  .desktop-container-cv {
    display: none;
  }
  .mobile-container-cv {
    display: block;
    margin-top: 0 !important;
    margin-bottom: 32px;
    background-color: transparent;
  }
  .line-rectangle1 {
    margin-top: 60px;
    width: auto;
  }
  .weiter-dokumente {
    justify-content: center;
    width: 100% !important;
    margin: 40px 49px 21px 0;
  }
  .field-1-weiter-dokumente {
    width: 100% !important;
    border: none;
    display: block !important;
    height: 0;
  }
  .weitere-dokumente-hi {
    width: 100% !important;
  }
  .other-docs {
    margin-bottom: 38px !important;
  }
  .itsme-doc-list-title {
    max-width: 634px;
  }
  .itsme-doc-list-title .panel-heading {
    max-width: 200px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .adj {
    max-width: 50% !important;
    flex: 0 !important;
  }
  .linkedin-description-page2 {
    width: 252px !important;
  }
  .card-deck .card {
    min-width: 240px;
  }
  .mit-linked-in-verknup1 {
    width: auto;
  }
  .ihr-vollstandiges-li {
    width: auto !important;
  }

  .recordingAlignment {
    padding-top: 3%;
  }
  .input-group-prepend.displayRecordStop,
  .input-group-prepend.displayPlayRecord {
    margin-top: 4%;
  }

  .schritt-row {
    width: 100% !important;
  }
  .distanceLeft {
    margin-left: 9%;
  }
  .steps-slider .rectangle-home-2 {
    max-width: 245px;
    height: 250px;
    margin-left: 5px;
  }
  .steps-slider .rectangle-home-3 {
    max-width: 245px;
    height: 250px;
    margin-left: 5px;
  }
  .du-ladst-dein-cv-hoc {
    // max-width:250px;
  }
  .du-ladst-dein-cv-hoc-3 {
    max-width: 250px;
    padding-right: 5px;
  }
  .button-primary-primary {
    width: 270px;
    //margin-left: 23px;
  }
  .coding-switch {
    width: 270px;
    margin-left: 23px;
  }
  .coding-switch-kaffi {
    width: 270px;
    margin-left: 23px;
  }
  .distanceBottom {
    padding-bottom: 10.5%;
  }
  .modal-header {
    padding: 24px 21px 24px 17px !important;
  }
  .footer {
    padding-bottom: 0 !important;
  }
  .selectReactionBoxDdlView {
    /*margin-left: 5%; */
  }
  div#errorReferalEmployee {
    width: 70% !important;
    margin-left: 3%;
  }
}

@media only screen and (min-width: 601px) {
  .und-so-gehts-weiter-copy {
    font-weight: 800;
    color: #001155;
    font-family: TheSans, sans-serif;
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 48px;
    text-align: center;
    margin-top: 80px;
  }
  .checkBoxAlert {
    border-radius: 0 !important;
    max-width: 50%;
    margin: auto;
    margin-top: 1%;
    margin-left: 25%;
    margin-bottom: 2%;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-size: 18px !important;
    padding: 21px 38px !important;
    color: white !important;
    background-color: #cf4a0c !important;
    border-color: #cf4a0c !important;
  }

  .recordingAlignment {
    /*margin-top: 41px;*/
  }
  .input-group-prepend.displayRecordStop,
  .input-group-prepend.displayPlayRecord {
    margin-top: 4%;
  }

  .styleDanger {
    margin-left: 2.5%;
    width: 58.5% !important;
  }
  div#errorReferalEmployee {
    max-width: 624px;
  }
  .schritt-row {
    width: 100%;
  }
  .rectangle-home-2 {
    width: 30%;
  }
  .rectangle-home-3 {
    width: 30%;
  }
  .du-ladst-dein-cv-hoc-3 {
    width: 100%;
  }
  .du-ladst-dein-cv-hoc {
    //width:100%;
  }
  .distanceLeft {
    margin-left: 20.5%;
  }
  .distanceBottom {
    padding-bottom: 7%;
  }
  .modal-header {
    padding: 24px 20px 24px 17px !important;
  }
  .footer {
    padding-bottom: 0 !important;
  }
}

.trash-dim {
  height: 30px;
  width: 30px;
}

.itsme-doc-list-title {
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 5px 0;
}

#itsme-ul-file-list {
  list-style-type: none;
  padding-left: 0;
}

#cv-itsme-ul-file-list {
  list-style-type: none;
  padding-left: 0;
}

.other-file-first-title {
  color: #333333;
}

.disabledField {
  color: #d3d3d3 !important;
}

.disabledBorder {
  border: 1px solid #d3d3d3 !important;
}

.errorUrl {
  color: red;
  text-align: left;
  margin-left: 1rem;
  display: none;
}

.modal-header .close {
  padding-right: 0;
}

.margins-modal-header {
  margin-bottom: 2rem;
  margin-right: 2rem;
}

a {
  cursor: pointer;
}

.itsme-document-alert-box,
.itsme-gender-alert-box {
  display: none;
  text-align: left;
  max-width: 624px;
  margin-top: 5px;
}

.itsme-document-error-cross {
  float: right;
  margin-right: -5px;
}

#itsme-file-list {
  display: none;
  text-align: left;
  max-width: 624px;
}

#itsme-file-list-2 {
  text-align: left;
  max-width: 624px;
}

.rectangle-home {
  height: 220px;
  float: left;
  max-width: 31.2%;
  border-radius: 5px;
  background-color: #ffffff;
  margin-left: 24px;
  box-shadow: 0 2px 24px 0 rgba(17, 170, 255, 0.1);
}

.schritt-1 {
  height: 40px;
  max-width: 286px;
  margin-top: 40px;
  position: absolute;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 40px;
  font-weight: 800;
  margin-left: 28px;
}

.bi-hand-thumbs-up {
  padding-left: 24px;
  margin-top: 24px;
}

.cloud-dev-engineer-home {
  // margin-top:56px;
}

.steps-slider {
  margin-top: 40px;
}

.bs-stepper-header {
  background-color: #ffffff;
  justify-content: center;
  box-shadow: 0 2px 20px 0 rgb(27 70 109 / 10%);
  position: fixed;
  bottom: 0;
  width: 100%;
}

.line {
  flex-grow: 0.1 !important;
  margin: 0 !important;
}

.step-trigger {
  font-size: 14px !important;
  font-weight: normal !important;
}

.complete .bs-stepper-circle {
  background-color: #25b252 !important;
  color: white !important;
  border: 2px solid #25b252 !important;
}

.itsme-temp-main-container {
  height: calc(100vh - (62px + 92px));
  display: flex;
  align-items: center;
}

.itsme-temp-main-container h1 {
  border-radius: 25px;
  border-style: dotted;
  padding: 9% 5%;
  color: #001155;
  font-weight: bold;
  font-family: TheSans, sans-serif;
  border-width: 2px;
}

.align-content-center {
  text-align: center;
}

.backgroundButtonSubmit {
  max-height: 48px;
  max-width: 316px;
  border-radius: 5px;
  background-color: #1781e3;
  vertical-align: middle;
  padding: 9px 28px 9px 29px;
}

.backgroundButtonSubmitCoffeeTalk {
  max-height: 48px;
  width: 327px;
  border-radius: 5px;
  background-color: #1781e3;
  vertical-align: middle;

  text-align: center;
}

.svgIconAlign {
  vertical-align: top;
  fill: white;
  margin: 0 !important;
}

.alignMiddle {
  margin-right: 1%;
  margin-top: 3%;
}

.cv-lebenslauf {
  max-height: 18px;
  max-width: 624px;
  color: #666666;
  font-family: TheSans, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin-bottom: -4px !important;
}

@media only screen and (min-width: 1201px) and (max-width: 1325px) {
  .styleDanger {
    margin-left: 2.5%;
    width: 58.5%;
  }
  #privacyPolicyCheckbox,
  .internalEmpArea {
    padding-left: 54px;
    padding-right: 54px;
  }
  div#errorReferalEmployee {
    width: 56%;
  }
  .distanceLeft {
    margin-left: 25%;
  }
  .distanceBottom {
    padding-bottom: 6.5%;
  }
  .modal-header {
    padding: 24px 20px 24px 17px !important;
  }
  .footer {
    padding-bottom: 0 !important;
  }
}

.rectangle-home-2 {
  text-align: left;
  height: 220px;
  float: left;

  border-radius: 5px;
  background-color: #ffffff;
  margin-left: 24px;
  box-shadow: 0 2px 24px 0 rgba(17, 170, 255, 0.1);
}

.rectangle-home-3 {
  text-align: left;
  height: 220px;
  float: left;

  border-radius: 5px;
  background-color: #ffffff;
  margin-left: 24px;
  box-shadow: 0 2px 24px 0 rgba(17, 170, 255, 0.1);
}

.icons-bold-pointer {
  height: 60px;
  width: 60px;
}

.footer-link {
  margin-left: 20px;
}

/*--Screen 5 css--*/
.deine-bewerbung-wurd {
  color: rgb(27, 135, 18);
  font-family: TheSans, sans-serif;
  font-size: 64px;
  letter-spacing: -1px;
  line-height: 72px;
  text-align: center;
  font-weight: 800;
  max-width: 880px;
  height: auto;
}

.und-so-gehts-weiter {
  // max-height: 48px;
  // max-width: 428px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 40px;
  letter-spacing: -1px;
  line-height: 48px;
  text-align: center;
  font-weight: 800;
  margin-top: 80px;
}

/*Screen 5 CSS end --*/

/*CSS for Modal Popup starts*/
.link-hinzufugen {
  height: 28px;
  width: 490px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: -0.25px;
  line-height: 28px;
  text-align: left;
  font-weight: 800;
}

.modal-content {
  border-radius: 0;
}

.hast-du-ein-online-p {
  height: 64px;
  width: 526px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: left;
}

.background-btn {
  height: 48px;
  width: 160px;
  border-radius: 5px;
  background-color: #1781e3;
  border-color: white;
}

.background-btn span {
  height: 24px;
  width: 160px;
  color: #ffffff;
  font-family: TheSans, sans-serif;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

/*CSS for Modal Popup ends*/
.fa-xing {
  color: white;
  font-size: 20px;
}

.https-github-com {
  height: 24px;
  width: 284px;
  color: #1781e3;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  float: left;
  text-align: left;
}

.bin {
  float: right;
}

.line-3-copy-5 {
  border-bottom: 1px solid #d1e6f9;
}

.link-1 {
  height: 30px;
  width: 653px;
}

/*Progress Bar CSS starts --*/
.progress {
  width: 50%;
  height: 0.5rem;
}

.loaderTitle {
  width: 50%;
}

.cv-alex-muller-pdf {
  color: #666666;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  border: none;
  background: #f1f7fd;
}

.form-control.sc-cv-audio-input {
  border: none !important;
}

.bs-stepper-circle {
  border: 2px solid #007bff;
  color: #007bff !important;
  background-color: white !important;
  padding: 5px;
}

.active .bs-stepper-circle {
  background-color: #007bff !important;
  color: white !important;
}

.active .bs-stepper-circle:hover {
  border-color: rgb(0, 72, 207) !important;
  background-color: rgb(0, 72, 207) !important;
  color: white !important;
}

.footer-row {
  padding: 20px;
}

.displayRecordStop,
.displayPlayRecord {
  display: none;
}

.displayPlayRecord .playAudio,
.displayRecordStop .audioRecording {
  padding-top: 0 !important;
  padding-left: 16px !important;
}

#google_translate_element {
  float: right;
  margin-right: 16px;
  margin-top: 20px;
}

.goog-te-gadget-icon {
  display: none;
}

.goog-te-gadget-simple {
  color: #fff;
  //background-color: #001155;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid gray;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
}

.goog-te-gadget-simple .goog-te-menu-value {
  color: #001155;
}

#profile-list {
  text-align: left;
  max-width: 624px;
}

.goog-te-menu-value:hover {
  text-decoration: none;
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1031;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin1 {
  display: none;
  position: fixed;
  z-index: 9999; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
}

// #cover-spin::after {
//     content:'';
//     display:block;
//     position:absolute;
//     left:48%;top:40%;
//     width:70px;height:70px;
//     border-style:solid;
//     border-top: 30px solid #5fbaef;
//     border-right: 30px solid #5fbaef;
//     border-bottom: 30px solid #5fbaef;
//     border-left: 30px solid #ea5964;
//     border-top-color: #5fbaef;
//     border-width: 7px;
//     border-radius:50%;
//     -webkit-animation: spin .8s linear infinite;
//     animation: spin .8s linear infinite;
// }

.bi-keyboard-home {
  margin-right: 15px;
}

#toaster {
  display: none;
  max-width: 100% !important;
}

#toastererror {
  display: none;
  max-width: 100% !important;
  margin-top: 0 !important;
}

.button-primary-primary:hover {
  border-color: #0048cf;
  background-color: #0048cf;
}

.rectangle:hover {
  border-color: #0048cf;
  background-color: #0048cf;
}

.sc-cv-audio-input-text:hover {
  border-color: #0048cf;
  background-color: #0048cf;
}

button {
  cursor: pointer;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-te-menu-frame {
  width: 98px !important;
}

.goog-te-menu-value span:nth-child(5) {
  display: none;
}

.goog-te-menu-value span:nth-child(3) {
  display: none;
}

.arrowdown {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2px;
}

.menu-flyout-toggle-link .icon {
  margin-left: 8px;
}

.menu-flyout-toggle-link {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgb(102, 102, 102) !important;
  text-shadow: none !important;
  -webkit-font-smoothing: inherit !important;
  bottom: 1px !important;
  position: relative !important;
}

.languagedrop {
  float: right;
  margin: 22px;
}

.icon-013-chevron-down::before {
  content: '';
}

.notification-header {
  position: relative;
  display: none;
  overflow: hidden;
  height: 0;
  transition: height 400ms cubic-bezier(0.35, 0.035, 0.275, 0.9);
  margin-top: 10px;
  max-width: 654px;
  z-index: 0;
  width: 100%;
}

.notification-header .notification__content {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 74px;
  padding: 21px 24px;
  transition: background 400ms cubic-bezier(0.35, 0.035, 0.275, 0.9);
  background: #086adb;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1;
  outline: none;
}

.notification-header .notification__content:hover {
  background: #0048cf;
}

@media (min-width: 768px) and (max-width: 900px) {
  .notification-header .notification__content {
    padding: 21px 38px;
  }
  #privacyPolicyCheckbox,
  .internalEmpArea {
    padding-left: 54px;
    padding-right: 54px;
  }
  .rectangle-home-1 {
    text-align: left;
    height: 221px !important;
    min-height: 358px !important;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 24px 0 rgb(17 170 255 / 10%);
    margin-bottom: 20px;
  }
}

.notification-header .notification__content .notification__body {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  opacity: 0;
  animation: fade-in 300ms 400ms forwards cubic-bezier(0.35, 0.035, 0.275, 0.9);
}

.notification-header .notification__content .notification__body strong {
  font-weight: 600;
  font-size: 16px;
}

.notification-header .notification__content button {
  position: relative;
  right: -5px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  opacity: 0;
  animation: fade-in 300ms 400ms forwards cubic-bezier(0.35, 0.035, 0.275, 0.9);
}

.notification-header .notification__content button .icon {
  line-height: 32px;
  color: #fff;
  font-size: 34px;
}

.notification--open {
  height: 74px;
  z-index: 0;
}

.notification--alert .notification__content {
  background: #cf4a0c;
}

.notification--alert .notification__content:hover {
  background: #ba3e06;
}

.notification--confirmation .notification__content {
  background: #1b8712;
}

.notification--confirmation .notification__content:hover {
  background: #0d6f2c;
}

.icon {
  display: inline-block;
  text-transform: none;
  text-decoration: none;
  text-rendering: auto;
  line-height: 1;
  font-family: sdx-icons, sans-serif;
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-022-close::before,
.icon-close::before {
  content: '';
}

.notification__close {
  margin: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
  color: inherit;
}

#itsme-document-alert-box {
  display: none;
}

@keyframes notification-show {
  0% {
    height: 0;
  }
  100% {
    height: 74px;
  }
}

.left-content {
  height: 32px;
  width: 249px;
  color: #333333;
  font-family: TheSans, serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
}

.left-content a {
  color: #333333;
}

.left-content a:hover {
  text-decoration: none;
}

.linkdin-button {
  margin-top: 52px;
}

.widthAlignmentLinkButton {
  max-width: 619px !important;
}

div:empty {
  height: 0;
  margin: 0;
  padding: 0;
}

sdx-input,
sdx-select {
  margin-bottom: 25px;
}

.flex-row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.sc-form-document-container.cv-upload-container {
  margin-top: 8px;
}

.about-position-container {
  padding-bottom: 56px;
}

.document-ulpload-sec-DifferentProfile {
  padding-bottom: 80px;
}

.audioSectionDifferentProfile {
  margin-top: 0 !important;
}

#iyqz {
  height: 62px;
  background-color: white;
}

#ikmi {
  height: 62px;
  background-color: white;
}

.verticalAlignMiddle {
  vertical-align: middle;
}

.block-tiles-row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 49px;
  padding: 0 54px;
}

.block-tiles-col {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.classBottomMargin {
  margin-bottom: 140px !important;
}

.bs-stepper-circle {
  border-radius: 1em;
  width: 2em;
  margin: 0.25rem;
  line-height: 1em;
}

.step-trigger {
  display: inline-flex;
  padding: 20px;
  background-color: transparent;
  border: none;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
}

.line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}
