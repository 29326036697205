.icons-bold-drafts-3 {
  height: 70px;
  width: 70px;
  color: #25b252;
}

.vielen-dank-fur-dein {
  margin-left: auto;
  margin-right: auto;
  height: 96px;
  width: 428px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.und-so-gehts-weiter {
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
  height: 48px;
  width: 428px;
  color: #001155;
  font-family: TheSans, sans-serif;
  font-size: 40px;
  letter-spacing: -1px;
  line-height: 48px;
  text-align: center;
}

.wir-melden-uns-bei-d {
  margin-left: auto;
  margin-right: auto;
  height: 64px;
  width: 428px;
  color: #333333;
  font-family: TheSans, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.button-copy-5-1 {
  height: 48px;
  width: 334px;
}

.button-copy-5 {
  height: 48px;
  width: 234px;
}

.confirmation-page-job-title {
  font-weight: 800;
  margin: 10px 0 30px;
}
