.not-found-page {
  height: 100vh;
  background-color: #fff;

  /********************************************************************************
 * Reset
 ********************************************************************************/

  /* this intrusion is necessary, otherwise the header would not display correctly anyway */
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  div.scs-pageheader *,
  div.scs-pagefooter * {
    margin: 0;
    padding: 0;
    outline: 0;
  }

  div.scs-pageheader img,
  div.scs-pagefooter img {
    border: 0;
  }

  div.scs-pageheader ol,
  div.scs-pageheader ul,
  div.scs-pagefooter ol,
  div.scs-pagefooter ul {
    list-style: none;
  }

  div.scs-pageheader table,
  div.scs-pagefooter table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  /* Workaround to fix layout problem with legacy content (GHQ and help) using old CSS
----------------------------------------------------------------------------------------------------*/

  section.wrapper nav.leftMenu {
    margin-left: 17px;
    margin-right: 68px;
  }

  /********************************************************************************
 * Accessibility
 ********************************************************************************/

  div.scs-pageheader .scs-pageheader-accessibility,
  div.scs-pageheader h1.scs-pageheader-accessibility,
  div.scs-pageheader h2.scs-pageheader-accessibility,
  div.scs-pageheader h3.scs-pageheader-accessibility,
  div.scs-pagefooter .scs-pagefooter-accessibility,
  div#scs-pagefooter-contentWrapper .scs-pagefooter-accessibility,
  div.scs-pagefooter h1.scs-pagefooter-accessibility,
  div.scs-pagefooter h2.scs-pagefooter-accessibility,
  div.scs-pagefooter h3.scs-pagefooter-accessibility,
  div.scs-pageheader .accessibility,
  div.scs-pageheader h1.accessibility,
  div.scs-pageheader h2.accessibility,
  div.scs-pageheader h3.accessibility,
  div.scs-pagefooter .accessibility,
  div.scs-pagefooter h1.accessibility,
  div.scs-pagefooter h2.accessibility,
  div.scs-pagefooter h3.accessibility {
    position: absolute;
    left: -32768px;
    top: -32768px;
  }

  div.scs-pageheader .skiplinks {
    position: absolute;
    z-index: 10;
    width: 100%;
    text-align: center;
  }

  div.scs-pageheader .skiplinks a {
    position: absolute;
    top: -40px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: white;
    opacity: 0;
    filter: Alpha(Opacity = 0);
  }

  div.scs-pageheader .skiplinks li:last-child a {
    -webkit-transition: opacity 0.4s ease-out, top 1s ease-in;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }

  div.scs-pageheader .skiplinks li:last-child a:focus {
    -webkit-transition: all 0;
  }

  div.scs-pageheader .skiplinks a:focus {
    top: 0;
    text-decoration: underline;
    opacity: 1;
    filter: Alpha(Opacity = 100);
  }

  div.scs-pageheader .skiplinks li:first-child a:focus {
    -webkit-transition: opacity 0.3s ease-out, top 0.1s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }

  /********************************************************************************
* General Layout
********************************************************************************/

  /* do not change the following three lines, otherwise either IE8 or IE9 will break for sure! */
  @font-face {
    font-family: TheSaB5;
    src: url('../../fonts/TheSaB5_.eot');
    src: url('../../fonts/TheSaB5_.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/TheSaB5_.woff') format('woff'),
      url('../../fonts/TheSaB5_.ttf') format('truetype'),
      url('../../fonts/TheSaB5_.svg#TheSaB5_') format('svg');
  }
  @font-face {
    font-family: TheSaB3;
    src: url('../../fonts/TheSaB3_.eot');
    src: url('../../fonts/TheSaB3_.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/TheSaB3_.woff') format('woff'),
      url('../../fonts/TheSaB3_.ttf') format('truetype'),
      url('../../fonts/TheSaB3_.svg#TheSaB3_') format('svg');
  }
  @font-face {
    font-family: 'SwisscomIcons';
    src: url('../../fonts/swisscom-icons-webfont.eot');
    src: url('../../fonts/swisscom-icons-webfont.eot?#iefix')
        format('embedded-opentype'),
      url('../../fonts/swisscom-icons-webfont.woff') format('woff'),
      url('../../fonts/swisscom-icons-webfont.ttf') format('truetype'),
      url('../../fonts/swisscom-icons-webfont.svg#SwisscomIcons') format('svg');
  }

  div.scs-pageheader,
  div.scs-pagefooter {
    font-family: TheSaB5, 'Trebuchet MS', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  div.scs-pageheader .pageCenter,
  div.scs-pagefooter .pageCenter {
    margin: 0 auto;
    width: 980px;
    text-align: left;
  }

  div.scs-pageheader .clear,
  div.scs-pagefooter .clear {
    clear: both;
    font-size: 0;
    height: 0;
    line-height: 1px;
    overflow: hidden;
  }

  div.scs-pageheader .positionWrapper,
  div.scs-pagefooter .positionWrapper {
    position: absolute;
  }

  div.scs-pageheader a .linkImageWrapper {
    font-size: 0 !important;
    line-height: 0 !important;
  }

  div.scs-pageheader a:hover .linkImageWrapper {
    background-color: #11aaff;
  }

  div.scs-pageheader a:hover .linkImageWrapper img {
    opacity: 0.8;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    filter: alpha(opacity = 80);
  }

  #scs-pageheader-search-form {
    width: 140px;
  }

  #scs-pageheader-search-form input:focus,
  #scs-pagefooter-doormatnav a:focus {
    outline: none;
    color: #000;
  }
  .scs-accessibility-mode #scs-pageheader-search-form input:focus,
  .scs-accessibility-mode #scs-pagefooter-doormatnav a:focus {
    outline: #00f dotted 2px;
    color: #000;
    font-style: normal;
  }

  .scs-accessibility-mode #scs-pageheader-search-field.placeholder {
    color: #666;
    font-style: italic;
  }

  /* HEADLINE (CET03)
----------------------------------------------------------------------------------------------------*/

  div.scs-pageheader h2,
  .scs-pageheader-ui-dialog h2 {
    font: 21px TheSaB3, 'Trebuchet MS', Arial, Helvetica, sans-serif;
    color: #666;
    padding: 0;
    margin: 31px 0;
    text-align: left;
  }

  /* TOOLBAR (NEH02)
----------------------------------------------------------------------------------------------------*/

  div.scs-pageheader * {
    font-family: TheSaB5, 'Trebuchet MS', Arial, Helvetica, sans-serif !important;
  }

  div#scs-pageheader-toolbar {
    background: url(../../images/branchnavRightBg.png) repeat-x 50% 0;
  }

  div#scs-pageheader-toolbar .pageCenter {
    height: 40px;
    position: relative;
  }

  /* LOGO
----------------------------------------------------------------------------------------------------*/

  #scs-pageheader-logo {
    float: left;
    margin: 13px 0 13px -3px; /* align logo axis with tab above and text below */
    position: relative;
    z-index: 2;
  }

  #scs-pageheader-logo a,
  #scs-pageheader-logo a:hover,
  #scs-pageheader-logo a:active,
  #scs-pageheader-logo a:focus {
    text-decoration: none;
  }

  #scs-pageheader-logo .n-logo img {
    width: 40px;
  }

  #scs-pageheader-logo .n-logo,
  #scs-pageheader-logo .n-logotype {
    vertical-align: middle;
    display: inline-block;
  }

  #scs-pageheader-logo .n-logotype {
    overflow: hidden;
    display: none;
  }

  @media screen and (min-width: 1024px) {
    #scs-pageheader-logo .n-logotype {
      display: inline-block;
    }
  }

  *:first-child + html #scs-pageheader-logo .n-logo,
  * html #scs-pageheader-logo .n-logo {
    display: inline;
    zoom: 1;
  }

  #scs-pageheader-logo .n-logotype {
    font-family: SwisscomIcons, TheSans, sans-serif !important;
    font-size: 33.5px;
    color: #015;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
  }

  .scs-html-landingpage
    .scs-pageheader-themeDark
    #scs-pageheader-logo
    .n-logotype,
  .scs-pageheader-themeWhite #scs-pageheader-logo .n-logotype {
    color: white;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
  }

  div.scs-pagefooter .modDeviceSwitch,
  div.scs-pagefooter .scs-highcontrast-link {
    display: inline-block;
    padding-right: 17px;
  }

  #scs-pagefooter-contentWrapper {
    background: #f5f5f5 url(../../images/footer-top.png) repeat-x;
  }

  /* CORPORATE FOOTER (NEF3)
----------------------------------------------------------------------------------------------------*/

  #scs-pagefooter-metanav {
    padding: 13px 20px 0;
    height: 27px;
    text-align: right;
    font-size: 11px;
    color: #666;
  }

  #scs-pagefooter-metanav ul {
    float: left;
    list-style: none;
    margin-top: 0;
  }

  #scs-pagefooter-metanav ul li {
    float: left;
    margin-left: 20px;
  }

  #scs-pagefooter-metanav ul li:first-child {
    margin-left: 0;
  }

  #scs-pagefooter-metanav ul li a {
    font-size: 11px;
    color: #666;
    text-decoration: none;
  }

  #scs-pagefooter-metanav ul li a:focus,
  #scs-pagefooter-metanav ul li a:hover {
    color: #2d90ec;
  }

  /* do not change the following three lines, otherwise either IE8 or IE9 will break for sure! */
  @font-face {
    font-family: TheSaB5;
    src: url('../../fonts/TheSaB5_.eot');
    src: url('../../fonts/TheSaB5_.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/TheSaB5_.woff') format('woff'),
      url('../../fonts/TheSaB5_.ttf') format('truetype'),
      url('../../fonts/TheSaB5_.svg#TheSaB5_') format('svg');
  }
  @font-face {
    font-family: TheSaB3;
    src: url('../../fonts/TheSaB3_.eot');
    src: url('../../fonts/TheSaB3_.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/TheSaB3_.woff') format('woff'),
      url('../../fonts/TheSaB3_.ttf') format('truetype'),
      url('../../fonts/TheSaB3_.svg#TheSaB3_') format('svg');
  }
  @font-face {
    font-family: 'SwisscomIcons';
    src: url('../../fonts/swisscom-icons-webfont.eot');
    src: url('../../fonts/swisscom-icons-webfont.eot?#iefix')
        format('embedded-opentype'),
      url('../../fonts/swisscom-icons-webfont.woff') format('woff'),
      url('../../fonts/swisscom-icons-webfont.ttf') format('truetype'),
      url('../../fonts/swisscom-icons-webfont.svg#SwisscomIcons') format('svg');
  }

  body {
    font: 13px/16px TheSaB5, 'Trebuchet MS', Arial, Helvetica, sans-serif;
    color: #001155;
  }
  h1 {
    font: 36px TheSaB3, 'Trebuchet MS', Arial, Helvetica, sans-serif;
  }

  .sdx-container h1 {
    font-size: 32px;
  }

  h2 {
    font: 22px TheSaB3, 'Trebuchet MS', Arial, Helvetica, sans-serif;
  }

  .ad-declaration {
    display: block;
  }

  .teaserDiv {
    text-align: center;
  }

  .adTeaser {
    display: inline-block;
    margin-left: 1em;
  }

  .list.single-line.lower-margin {
    margin: 20px 0 16px;
  }

  .page {
    margin: 0px auto;
    width: 956px;
  }
  .icons {
    background: url(../../images/icons.png) no-repeat center;
    height: 200px;
  }
  a {
    color: #2d90ec;
    text-decoration: none;
  }
  .scs-primary-link a:hover,
  .scs-primary-link a:active {
    border-bottom: 1px solid #2d90ec;
    text-decoration: none;
  }

  .scs-primary-link {
    background: url('../../images/button-arrow-right.png') no-repeat left 1px;
    color: #2d90ec;
    display: inline-block;
    line-height: 1.3em;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    padding: 0 0 0 22px;
    text-decoration: none;
  }
  .textwithmargin {
    margin-left: 160px;
    width: 500px;
  }
  .textwrapper {
    width: 500px;
    height: 130px;
    padding-top: 3px;
  }

  .sdx-container .footer {
    z-index: 98;
  }

  .footer {
    position: absolute;
    width: 100%;

    @media only screen and (max-width: 768px) {
      position: static;
    }
  }

  #scs-pagefooter-metanav {
    padding: 13px 0 0 0;
    height: 27px;
    text-align: right;
    font-size: 11px;
    color: #666;
    margin-top: 50px;
  }

  #scs-pagefooter-metanav ul {
    float: left;
    list-style: none outside none;
    margin: 0;
    padding: 0;
  }

  #scs-pagefooter-metanav ul li {
    float: left;
    margin-left: 20px;
  }

  #scs-pagefooter-metanav ul li:first-child {
    margin-left: 0;
  }

  #scs-pagefooter-metanav ul li a {
    font-size: 11px;
    color: #666;
    text-decoration: none;
  }

  #scs-pagefooter-metanav ul li a:focus,
  #scs-pagefooter-metanav ul li a:hover {
    color: #2d90ec;
  }
  .left {
    float: left;
    width: 240px;
    height: 200px;
  }
  .right {
    float: left;
    padding-top: 55px;
  }
  .glass {
    background: url(../../images/glass.png) no-repeat right;
    width: 220px;
    height: 90px;
    margin: 50px 0 0 0;
  }
  .clear {
    clear: both;
  }
  #scs-search-form {
    padding: 0;
    margin: 0;
  }
  .scs-search-field {
    background: url(../../images/field.png) no-repeat;
    width: 370px;
    height: 25px;
    border: none;
    padding: 0 5px;
    float: left;
    outline: none;
  }
  .scs-search-button {
    background: url(../../images/btn.png) no-repeat;
    width: 60px;
    height: 25px;
    border: none;
    color: white;
    font: 13px/16px TheSaB3, 'Trebuchet MS', Arial, Helvetica, sans-serif;
    margin-left: 20px;
    padding: 0 0 2px;
  }
  .search-holder {
    padding-top: 35px;
  }

  ul.link-list li:before,
  .teaser ul.link-list li:before,
  .links ul li:before,
  span.link-list-item a:before,
  a span.link-list-item:before {
    content: '> ';
    color: #2d90ec;
    display: inline-block;
    width: 0.4em;
  }

  span.link-list-item a,
  a span.link-list-item {
    margin-left: 0.7em;
    line-height: 2em;
    text-indent: -0.4em;
  }

  @media screen and (max-width: 1000px) {
    .page {
      margin: 0px auto;
      width: 100%;
    }
    .glass {
      width: 100%;
      /*background: url(../../images/glass.png) no-repeat center;*/
    }
    .left {
      width: 25%;
    }

    .right {
      margin-left: 20px;
      width: 60%;
    }
    .textwrapper {
      width: 100%;
    }

    div.scs-pageheader .pageCenter,
    div.scs-pagefooter .pageCenter {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 400px) {
    .page {
      margin: 0px auto;
      width: 100%;
    }
    .glass {
      background: url(../../images/glass.png) no-repeat center;
      margin: 0;
      width: 70px;
      height: 65px;
      background-size: contain;
    }
    .left {
      width: 100%;
      height: auto;
    }
    .left > *,
    .right > * {
      padding: 0px 10px 0px 10px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border;
    }
    .right {
      width: 100%;
      text-align: center;
      margin-left: 0px;
      padding-top: 10px;
    }
    .textwrapper {
      width: 100%;
      height: 70px;
      padding-top: 0px;
    }

    .textwrapper,
    .linkwrapper {
      box-sizing: border-box;
    }

    div.scs-pageheader .pageCenter,
    div.scs-pagefooter .pageCenter {
      width: 100% !important;
    }

    .linksdiv {
      text-align: left;
      margin-left: 1em;
    }

    h1 {
      font-size: 32px;
    }

    #scs-pagefooter-metanav {
      position: relative;
      text-align: center;
    }

    #scs-pagefooter-metanav .copyrightDiv {
      position: absolute;
      top: 50px;
      right: 20px;
    }
  }

  nav {
    padding-top: 10px;
  }

  nav.container.no-side-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .content {
    flex: 1 0 auto;
  }

  .content div img {
    padding-left: 0;
    width: 100%;
    max-width: 220px;
    margin-bottom: 30px;
  }

  scs-search-input {
    display: block;
  }

  .row.reverse {
    flex-direction: column-reverse;
  }

  @media only screen and (min-width: 480px) and (max-width: 1023px) {
    .content div #lottie svg {
      max-width: 428px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .sdx-container h1 {
      font-size: 40px;
    }
    .content {
      padding-top: 80px;
    }

    .content div #lottie {
      background-color: #ffffff;
      display: block;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      text-align: center;
      opacity: 1;
    }

    .content div #lottie svg {
      max-width: 490px;
      padding-left: 60px;
    }

    .footer {
      width: 100%;
      position: fixed;
      bottom: 0;
    }

    .row.reverse {
      flex-direction: row;
      align-items: center;
    }

    nav.container.no-side-padding #scs-pageheader-logo {
      margin-left: -6px;
    }
  }

  nav a:link {
    border-bottom: none;
  }

  nav a:link:hover {
    border-bottom: none;
  }
}
