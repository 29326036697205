.file-upload-container {
  .file-upload-group {
    border: 1px dashed #d6d6d6;
    border-radius: 5px;
    background-color: #f1f7fd;
    box-sizing: border-box;
    margin-top: 15px;
    padding-top: 16px;
    width: 100%;
    height: auto;
  }
}

// Cv Upload component

.cv-file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  hr,
  .cv-area-txt-onlycv {
    max-width: 624px;
  }

  .cv-area-txt-onlycv {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .sw-cv-upload-card {
    max-width: 624px;
    border: 1px dashed #d6d6d6;
    border-radius: 5px;
    background-color: #f1f7fd;
    box-sizing: border-box;
    margin: 15px 0;
    width: 100%;
    @media only screen and (max-width: 600px) {
      background: none;
      border: none;

      .dokumente-hierher-zi,
      .oder {
        display: none;
      }
    }
    .card-body {
      background: none;
      margin: 0;
    }

    // File Selector button style
    .sw-file-button-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      @media only screen and (max-width: 600px) {
        margin-top: 0;
      }

      .btn-file-selector {
        background-color: transparent;
        box-sizing: border-box;
        height: 48px;
        width: 328px;
        border: 1px solid #0876db;
        border-radius: 5px;
        i {
          font-size: 20px;
        }
      }
    }
  }
}

// File Item
.sw-file-items-container {
  display: flex;
  flex-direction: column;
  max-width: 624px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 16px;

  .sw-file-item-container {
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    .sw-file-item-wrapper {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      &.itsme-doc-list-title {
        margin: 0;
      }

      .sw-file-item-name-container {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 1;
        i {
          margin-right: 10px;
        }

        .spinner-border {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }
      }
    }
  }
}

.other-doc-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-file-first-title {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
}

.SimpleApply-cv-form {
  .other-docs {
    margin-bottom: 44px;
  }
}
