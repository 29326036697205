.SimpleApply-other-form,
.SimpleApply-social-form {
  .linkedin-profile {
    margin-top: 24px;
  }
}

.TechChallenge-other-form {
  .linkedin-profile {
    margin-top: 24px;
  }
}

.submitButtonIcon {
  margin-right: .5rem!important;
  margin-bottom: 3px;
}

.sw-job-title {
  margin-bottom: 16px;
}

.modal-title h4 {
  font-size: 20px;
}
