@import './header';
@import './footer';

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
