.main-header {
  min-height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;

  .header-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 27px;
    margin-right: 18px;
  }
}
