.language-switcher {
  .language-dd-toggle {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: rgb(102, 102, 102) !important;
    text-shadow: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .current-language {
      text-transform: uppercase;
      font-weight: 400 !important;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
      text-shadow: none !important;
    }

    .hydrated {
      margin-left: 8px;
    }
  }
}
